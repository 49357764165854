import React from 'react'
import Img from 'gatsby-image'

export default function Header({ backgroundImage }) {
  return (
    <div class="mb-10 flex flex-col flex-grow h-screen" style={{ maxHeight: '1000px' }}>
      <div class="flex-1 md:h-full relative">
        <Img className="inset-0 absolute h-full w-full" fluid={backgroundImage} />
        <div class="inset-0 absolute h-full w-full bg-gradient-to-t from-black opacity-50"></div>
        <div class="inset-0 absolute h-full w-full flex flex-col justify-center items-center">
          <h1 class="text-5xl md:text-7xl text-center text-white" style={{ textShadow: 'rgba(0, 0, 0, 1) 0px 0px 10px' }}>Ferienwohnung Mediterran</h1>
        </div>
      </div>

      <div class="bg-white w-full container
                  flex flex-col self-center
                  justify-center items-start
                  md:transform md:-translate-y-16 md:-mb-16">
          <div class="flex flex-col md:flex-row
            justify-start
            items-start md:items-center
            md:h-16
            text-lg font-semibold text-gray-500
            space-y-4 md:space-y-0 md:space-x-10
          ">
            <a href="/" class="pt-4 md:pt-0">Startseite</a>
            <a href="/galerie">Galerie</a>
            <a href="/kontakt">Kontakt</a>
          </div>
        </div>
    </div>
  )
} 

