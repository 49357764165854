import React from 'react';
import Footer from './footer';
import Header from './header';
import SEO from './seo';

export default function Layout({ title, backgroundImage, children }) {
  return (
    <div class="min-h-full flex flex-col">
      <SEO title={title}></SEO>
      <Header backgroundImage={backgroundImage} />
      <div class="w-full container self-center flex flex-col items-center justify-center">
        { children }
      </div>
      <div class="flex-grow"></div>
      <Footer/>
    </div>
  )
}
