import React from 'react'

export default function Footer() {
  return (
    <div class="mt-10 w-full flex flex-col items-center justify-center">
      <hr class="w-full"/>
      <div class="w-full container
                  flex flex-col md:flex-row
                  space-y-5 md:space-y-0 md:space-x-10
                  justify-start items-center
                  py-5 md:py-0 md:h-16">
        <div class="">&copy; Ferienwohnung Mediterran</div>
        <div class="flex-grow"></div>
        <a href="/datenschutz">Datenschutz</a>
        <a href="/impressum">Impressum</a>
      </div>
    </div>
  )
}